$(document).ready(function() {
    function startTimer(duration, display) {
        var timer = duration, days, hours, minutes, seconds;

        setInterval(function () {
            days = parseInt(timer / 3600 / 24, 10);
            hours = parseInt((timer - (days * 86400)) / 3600, 10);
            minutes = parseInt((timer / 60) - (hours * 60) - (days * 24 * 60), 10);
            seconds = parseInt(timer % 60, 10);

            days = days < 10 ? "0" + days : days;
            hours = hours < 1 ? "0" + hours : hours;
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            display.text(days + " : " + hours + " : " + minutes + " : " + seconds);

            if (--timer < 0) {
                timer = duration;
            }
        }, 1000);
    }

    var timer = 163830, display = $('#countdown-timer');

    startTimer(timer, display);
});