$(document).ready(function() {
    $('.mobile-gallery .slider-container').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rows: 2
                }
            }
    ]}).on('setPosition', function (event, slick) {
        slick.$slides.css('height', slick.$slideTrack.height() + 'px');
    });
});


$.fn.is_on_screen = function(){
    var win = $(window);
    var viewport = {
        top : win.scrollTop(),
        left : win.scrollLeft()
    };
    viewport.right = viewport.left + win.width();
    viewport.bottom = viewport.top + win.height();
    var bounds = this.offset();
    bounds.right = bounds.left + this.outerWidth();
    bounds.bottom = bounds.top + this.outerHeight();
    return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
};
function isTargetVisble() {
    var retunVal = false;
    $('.hide-when-visible').each(function() {
        if( $(this).is_on_screen() ) {
            retunVal = true;
        }
    });
    return retunVal;
}
$(document).on( "scroll load resize", function() {
    if ( $(window).width() > 1040 ) {
        if ($(window).scrollTop() > 200) {
            $('#floating-cta').slideDown(200) // log info
        } else {
            $('#floating-cta').slideUp(200) // log info
        }
    } else {
        if( isTargetVisble() ) { // if target element is visible on screen after DOM loaded
            $('#floating-cta').slideUp(200) // log info
        } else {
            $('#floating-cta').slideDown(200) // log info
        }
    }
});
